@import "~bootstrap/scss/bootstrap";
@import "global";

body{
  background-color: #213240;
  overflow-x: hidden;
}
h1.main-title{
  font-size: 66px !important;
  padding-top: 250px !important;
  font-weight: 600;
  margin-left: 50px;
  color: #FFFFFF;
}
#my-video{
  padding-top:100px;
  //padding-right: 50px;
}
