
//Global styles that are used everywhere


// Import setting default bootstrap & custom variables
@import "variables";

//@import "pagination";

body{
  font-family: 'Tajawal', sans-serif;
}

@media (min-width: 1200px) {
  .grey-background{
    background-color: #f5f5f5;
  }
  .no-padding{
    padding: 0;
  }
  .no-padding-right{
    padding-right: 0;
  }
  .no-padding-left{
    padding-left: 0;
  }
}
.no-margin{
  margin-left: 0;
  margin-right: 0;
}

.navbar{
  height: 100px;
  font-size: 16px;
  color: #1c1c1c;
  text-transform: uppercase;
}
nav{
  background: #f2f2f2;
}
.navbar-nav{
  text-align: center;
  @include media-breakpoint-down(sm) {
    background-color: #1c1c1c;
  }
}
nav .navbar-nav li a{
  @include media-breakpoint-down(sm) {
  color: white !important;
  }
}
nav .navbar-nav li a:hover{
  color: #b99470;
}

.dropdown-menu{
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}
button.btn-teal.d-md-none.custom-mobile{
  width:42%;
}
.navbar-brand{
  width: 190px;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  @include media-breakpoint-down(sm){
    width:147px;
    height: unset;
  }
  img{
    height: 50px;
    margin-left: 31px;
    @include media-breakpoint-down(sm){
      height: 37px;
      margin-left: 27px;
    }
  }
}
.btn-teal{
  background: #b99470;
  text-transform: uppercase;
  width: 10%;
  font-size:12px;
  color: #fff;
  text-align: center;
  padding: 7px;
  border: none;
  margin: 15px 0;
height: 45px;
  border-radius: 0;
}
.btn-teal:hover{
  color: white;
}
.header-breadcrumbs{
  background-color: unset;
  font-size: 12px;
  margin-top: 14px;
  a{
    color:#138496;
  }
}

.table-responsive{
  select{
    background: unset;
    width: 65px;
  }
}
.dataTables_wrapper{
  padding: 0 25px;
  margin-top: 40px;
  table{
    border: 1px solid #e9ecef;
    thead{
      font-size: 14px;
      th{
        font-weight: 600;
      }

      }
    }
  }

td{
  form{
    a{
      color:#138496;
      margin-right: 18px;
    }
  }
}
.actions-area{
  .btn-teal{
    width: 100px;
    font-size: 14px;
    margin-right: 15px;
  }
  .btn-transparent{
    width: 100px;
    font-size: 14px;
  }
  .btn-danger{
    border-radius: 0;
    width: 100px;
    float: right;
    margin-top: 15px;
  }
}
.footer-content {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #151515;
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
  @include media-breakpoint-down(sm){
    font-size: 16px;
  }
  i{
    color:#c19b76;
    margin-right: 10px;
  }
  svg{
    color:#c19b76;
    margin-right: 10px;
  }
  img{
    margin-top: 10px;
    margin-bottom: 15px;
    width: 15%;
    @include media-breakpoint-down(sm){
      width: 33%  ;
    }
  }
}
.dropdown-item{
  font-size: 13px;
  color: #ffffff;
}
.dropdown-item:hover{
  background-color: unset;
  color:#c19b76;
}
a:hover {
  background-color: unset;
  color:#fca812;
}
.dropdown-menu{
  background-color:#151515;
}

.bg-green{
  background-color: $bg-green;
}
.container-fluid{
  background-color: #f2f2f2;
}
